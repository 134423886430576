import '../../../scss/react/shared-components/column-spinner.scss';

import * as React from 'react';

export class ColumnSpinner extends React.Component {
  public render() {
    return (
      <ul className="loading-indicator-r">
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    );

  }
}
