import '../../../../scss/react/shared-components/form-elements.scss';

import * as React from 'react';

interface DropDownProps {
  name: string;
  label: string;
  value: string | number;
  values: Array<{ selectionValue: string | number, selectionLabel: string }>;
  onChange: (currentTarget: React.FormEvent<HTMLSelectElement>) => void;
  onBlur?: (currentTarget: React.FormEvent<HTMLSelectElement>) => void;
  error: string;
  placeholderText?: string;
  ignorePlaceholderText?: boolean;
  autoFocus?: boolean;
  readOnly?: boolean;
  hidden?: boolean;
}

export const DropDown = React.forwardRef<HTMLSelectElement, DropDownProps>((props, ref) => {
  const { name, label, error, placeholderText, ignorePlaceholderText, readOnly,
    hidden, values, children, ...rest } = props;
  const options = values.map((option, index) => {
    return (<option key={index} value={option.selectionValue}>{option.selectionLabel}</option>);
  });
  return (
    <div className={'form-element-container' + (readOnly ? ' disabled' : '') + (hidden ? ' hidden' : '')}>
      <div className={'form-element-select' + (error ? ' error' : '')}>
        <div className="form-input-container">
          <select
            name={name}
            id={name}
            ref={ref}
            className={`form-input ${!props.value ? '' : 'item-selected'}`}
            disabled={readOnly}
            value={''}
            {...rest}
          >
            {!ignorePlaceholderText && <option value="">{placeholderText || 'Select a ' + label}</option>}
            {options}
          </select>
          <label className="form-input-label" htmlFor={name}>{label}</label>
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
});
