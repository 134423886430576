import * as _ from 'lodash';

import '../../../../images/icons/hide-password.svg';
import '../../../../images/icons/information.svg';
import '../../../../images/icons/show-password.svg';

import '../../../../scss/react/shared-components/form-elements.scss';

import * as React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

interface BaseInputProps {
  name: string;
  label: string;
  value: string | number | string[];
  onChange?: (currentTarget: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => void;
  onBlur?: (currentTarget: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => void;
  onClick?: (currentTarget: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement> | null) => void;
  onSubmitCallback?: () => void;
  usesOnSubmitCallback?: boolean;
  error: string;
  placeholderText?: string;
  autoFocus?: boolean;
  inputIcon?: string;
  readOnly?: boolean;
  hidden?: boolean;
  informationalText?: string;
}

interface InputProps extends BaseInputProps {
  type: string;
}

const InformationalSnippet = (informationalText: string) => (
  <span
    className="information-icon-container"
    title={informationalText}
  >
    <svg className="information-icon">
      <use xlinkHref={'#information'} />
    </svg>
  </span>
);

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { name, label, error, inputIcon, informationalText, placeholderText, children, readOnly, hidden,
    onSubmitCallback, usesOnSubmitCallback, ...rest } = props;
  return (
    <div className={'form-element-container' + (readOnly ? ' disabled' : '') + (hidden ? ' hidden' : '')}>
      <div className={'form-element-input' + (error ? ' error' : '')}>
        {inputIcon && (
          <div className="input-icon-label">
            <svg className="input-icon">
              <use xlinkHref={`#${inputIcon}`} />
            </svg>
          </div>
        )}
        <div className="form-input-container">
          <input
            name={name}
            id={name}
            ref={ref}
            className="form-input"
            placeholder={placeholderText || label}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter' && usesOnSubmitCallback) {
                event.preventDefault();
                onSubmitCallback();
              }
            }}
            readOnly={readOnly}
            {...rest}
          />
          <label className="form-input-label" htmlFor={name}>
            {label}
            {
              informationalText &&
              InformationalSnippet(informationalText)
            }
          </label>
        </div>
        {children}
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
});

interface TextareaProps extends BaseInputProps {
  rows?: number;
  maxRows?: number;
}

export const TextAreaInput = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const {
    name, label, error, informationalText, placeholderText, children, readOnly, hidden, value, rows, maxRows, ...rest
  } = props;
  return (
    <div className={'form-element-container' + (readOnly ? ' disabled' : '') + (hidden ? ' hidden' : '')}>
      <div className={'form-element-textarea' + (error ? ' error' : '')}>
        <div className="form-input-container">
          <TextareaAutosize
            name={name}
            id={name}
            ref={ref}
            className="form-input"
            placeholder={placeholderText || label}
            readOnly={readOnly}
            data-input-value={value}
            value={value || ''}
            {...rest}
            rows={rows ? rows : 5}
            maxRows={maxRows ? maxRows : 10}
          />
          <label className="form-input-label" htmlFor={name}>
            {label}
            {
              informationalText &&
              InformationalSnippet(informationalText)
            }
          </label>
        </div>
        {children}
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
});

interface MultiAddProps extends InputProps {
  list: string[];
  limit?: number;
  limitText?: string;
  exceptions?: string[];
  addItem: (item: string, overLimit: boolean, itemAlreadyExists: boolean) => boolean;
  removeItemCallback?: (index: number) => void;
}

interface MultiAddInputState {
  currentText: string;
  isFocused: boolean;
}

export class MultiAddInput extends React.Component<MultiAddProps, MultiAddInputState> {
  private inputRef: React.RefObject<HTMLInputElement>;

  public constructor(props: MultiAddProps) {
    super(props);

    this.state = {
      currentText: '',
      isFocused: false,
    };

    this.inputRef = React.createRef();

    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  public render() {
    const {
      name, label, error, informationalText, children, readOnly, hidden, list, limit,
      limitText, exceptions, addItem, removeItemCallback,
    } = this.props;

    return (
      <div className={'form-element-container' + (readOnly ? ' disabled' : '') + (hidden ? ' hidden' : '')}>
        <div
          className={'form-element-multi-add-input' + (error ? ' error' : '')}
        >
          <div className="form-input-container-multi-add">
            <span className="badge-container">
              {list.map((element: string, index: number) => {
                return (
                  <div
                    className={`badge ${!exceptions
                      || (exceptions && !exceptions.map((item) => item.toLowerCase()).includes(element.toLowerCase())) ?
                      'badge-secondary' : 'badge-primary'}`}
                    key={index}
                  >
                    {element}
                    <span
                      className="badge-remove-btn"
                      onClick={() => removeItemCallback(index)}
                    >
                      &nbsp;×
                    </span>
                  </div>
                );
              })}
              <input
                ref={this.inputRef}
                type="text"
                name={name}
                id={name}
                className="form-input-multi-add"
                value={this.state.currentText}
                readOnly={readOnly}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (!(event.key === 'Tab' && this.state.currentText === '')) {
                    if (event.key === 'Enter' || event.key === ',' || event.key === ';' || event.key === 'Tab') {
                      event.preventDefault();
                      this.addItemAndClear(addItem, list, exceptions, limit);
                    }
                    if ((event.key === 'Backspace' || event.key === 'Delete') && this.state.currentText === '') {
                      event.preventDefault();
                      removeItemCallback(list.length - 1);
                    }
                  }
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.setState({ currentText: event.currentTarget.value });
                }}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              />
            </span>
            {this.state.currentText.trim() !== '' && this.state.isFocused ?
              <div
                className="multi-add-preview-dropdown"
                onMouseDown={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  this.addItemAndClear(addItem, list, exceptions, limit);
                }}
              >
                Add <strong>{this.state.currentText}</strong>...
              </div> : null
            }
            <label className="form-input-label" htmlFor={name}>
              {label}&nbsp;
              {limit && limitText ?
                <span>
                  ({this.getEffectiveListLength(list, exceptions)} of {limit} {limitText} used)
                </span>
                : null
              }
              {
                informationalText &&
                InformationalSnippet(informationalText)
              }
            </label>
          </div>
          {children}
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    );
  }

  private addItemAndClear(addItemCallback: (item: string, overLimit: boolean, itemAlreadyExists: boolean) => boolean,
                          list: string[] = [], exceptions: string[] = [], limit: number) {
    const inputArray = this.state.currentText.trim().split(';');
    const effectiveListLength = this.getEffectiveListLength(list, exceptions);

    let clear: boolean;
    const invalidItems: string[] = [];

    for (let i = 0; i < inputArray.length; i++) {
      const inputItem = inputArray[i].trim();
      const overLimit = limit > 0 ? (effectiveListLength + i >= limit ? true : false) : false;
      const itemAlreadyExists = _.includes(list.map((item) => item.toLowerCase()), inputItem.toLowerCase());
      const itemIsExemptFromLimit = _.includes(exceptions.map((item) => item.toLowerCase()), inputItem.toLowerCase());
      if (itemIsExemptFromLimit) {
        clear = addItemCallback(inputItem, false, itemAlreadyExists);
      } else if (inputItem.length > 0) {
        clear = addItemCallback(inputItem, overLimit, itemAlreadyExists);
      }
      if (!clear) {
        invalidItems.push(inputItem);
      }
    }
    if (invalidItems) {
      this.setState({ currentText: invalidItems.join(';') });
    } else {
      this.setState({ currentText: '' });
    }
  }

  private getEffectiveListLength(list: string[], exceptions: string[]) {
    const tempList = list.slice();
    const inputArray = this.state.currentText.toLowerCase().trim().split(';');
    tempList.concat(inputArray);
    const exceptionsToLower = exceptions.map((item) => item.toLowerCase());
    const numberOfExceptions =
      tempList.filter((value) => _.includes(exceptionsToLower, value.toLowerCase())).length;
    return tempList.length - numberOfExceptions;
  }

  private handleBlur() {
    if (this.state.currentText) {
      this.inputRef.current.focus();
    } else {
      this.setState({ currentText: '', isFocused: false });
    }
  }

  private handleFocus() {
    this.setState({ isFocused: true });
  }
}
